import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import ArticleContent from '../components/ArticleContent';
import ArticleTableOfContents from '../components/ArticleTableOfContents';
import ArticleLayout from '../components/Layout/ArticleLayout';
import { getUrlWithoutLast } from '../utils';

const HiddenAnswersContent = styled(ArticleContent)`
  ul {
    li {
      &,
      a,
      span,
      pre,
      code {
        color: black !important;
        background: black !important;
        transition: all 400ms cubic-bezier(0.75, 0.22, 1, -0.35);
      }
      &:hover {
        &,
        code,
        a,
        span,
        pre,
        code {
          background: white !important;
        }
      }
    }
  }
`;

const IndexPage = ({ data, location }) => {
  const { pageContent } = data;
  return (
    <ArticleLayout>
      <ArticleTableOfContents
        html={pageContent.tableOfContents}
        backlink={getUrlWithoutLast(location.pathname)}
      />
      <HiddenAnswersContent html={pageContent.html}>
        <p>Najedź myszką na czarny box żeby sprawdzić odpowiedź.</p>
      </HiddenAnswersContent>
    </ArticleLayout>
  );
};

export default IndexPage;

export const query = graphql`
  query($id: String!) {
    pageContent: markdownRemark(frontmatter: { id: { eq: $id } }) {
      html
      tableOfContents
    }
  }
`;
